@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}
a {
  text-decoration: none;
  color: #ff470d;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ht-100v {
  height: 100vh;
}
.d-flex {
  display: flex !important;
}
.align-self-center {
  align-self: center !important;
}
.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.flex-column,
.flex-row {
  -webkit-box-direction: normal !important;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.border-start {
  border-left: 1px solid #dee2e6 !important;
}
.auth {
  background-color: #ff6e40;
}
.auth .form-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100%;
  padding: 30px 15px;
}
@media (min-width: 1200px) {
  .auth .form-wrapper {
    width: 1000px;
    margin: auto;
  }
}
.auth .card-block {
  border: none;
  margin: auto;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;

  background-color: #fff;
}
.auth .card-block .bg-light {
  padding: 30px;

  min-height: 500px;
}
.bg-light h3 {
  font-size: 1.53125rem;
  font-weight: 700 !important;
}
.bg-light h1 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.2;
}
.lead {
  font-size: 1.09375rem;
  font-weight: 400;
}
.text-center {
  text-align: center;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.list-inline-item {
  display: inline-block;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
/* .ant-input-affix-wrapper,
.ant-input,
.ant-picker {
  padding: 0.275rem 0.2rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.ant-input-number {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 0.2rem 0.8rem;
  font-size: 0.875rem;
  font-weight: 400;
  width: 100%;
} */
.ant-form-item {
  margin-bottom: 1rem;
}
.ant-form-item-label > label {
  height: auto;
  color: #000 !important;
}
.ant-btn {
  border-radius: 5px;
  font-size: 12px;
  height: auto;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #ff6e40;
  background: #fff;
  border-color: #ff6e40;
}
.ant-btn-primary {
  color: #fff;
  background-color: #ff6e40;
  border-color: #ff6e40;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff !important;
  background-color: #d95e36;
  border-color: #cc5833;
}
.ant-btn-primary:active {
  color: #fff;
  background-color: #ff6e40;
  border-color: #ff6e40;
}
@media (min-width: 768px) {
  .offset-md-1 {
    padding: 0 60px 0 40px;
  }
}
.text-right {
  text-align: right;
}
.after-login-logo {
  padding-left: 8px;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 80%;
}
.site-layout .site-layout-background,
.ant-layout {
  background: #fff;
}
.ant-menu {
  font-size: 15px;
}
.ant-layout-sider {
  background: #fff;
  border-right: solid 1px #f5f4fe;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  background: rgba(255, 110, 64, 0.15);
  color: #ff6e40;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(255, 110, 64, 0.15);
  color: #ff6e40;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #ff6e40;
}
.list-user-action a {
  font-size: 12px;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 18px;
  margin: 0 3px;
  background: rgba(255, 110, 64, 0.15);
  color: #ff6e40;
  display: inline-block;
  border-radius: 5px;
}
.ant-btn-text {
  font-size: 14px;
  height: auto;
  /* width: 25px; */
  text-align: center;
  line-height: 25px;
  margin: 0 3px;
  background: rgba(255, 110, 64, 0.15);
  color: #ff6e40;
  display: inline-block;
  border-radius: 5px;
  padding: 1px 6px;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  background: #f5f5f5;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0.1rem 0.01rem;
  text-align: center;
  font-weight: normal;
}
.ant-table-thead > tr > th {
  background-color: #293134;
  color: #fff;
}
.ant-table,
.ant-progress-text,
.ant-select,
.ant-descriptions-item-label,
.ant-input-number,
.ant-input,
.ant-select-item,
.ant-picker-dropdown,
.ant-descriptions-item-content,
.ant-list,
.ant-breadcrumb,
.ant-tooltip,
.ant-message,
.ant-picker-input > input {
  font-size: 12px;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 11px;
  line-height: 11px;
  min-height: inherit;
}
.ant-input-number-input {
  height: auto;
  padding: 0;
}
.production-master-modal .ant-picker {
  width: 100%;
}
/* .ant-select-selector {
  border-radius: 4px !important;
  padding: 0.275rem 0.2rem !important;
} */
.ml-20 {
  margin-left: 20px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-0 {
  margin-bottom: 0;
}
.ant-card {
  border-radius: 0;
}
.ant-card-body {
  padding: 0.5rem;
}
/* .components-table-demo-nested .ant-table-content .ant-table-content {
  margin: 20px 0;
} */
.langselect .ant-select-selector {
  padding: 0 11px !important;
}
.langselect .ant-select {
  margin-right: 20px;
}
.ant-layout-header {
  padding: 0 20px;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border-right: 1px solid #484b4b;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr:not(:last-child)
  > th {
  border-bottom: 1px solid #484b4b;
}
@media only screen and (max-width: 600px) {
  .sm-mt-15 {
    margin-top: 15px;
  }
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 3px 5px;
}
.activitycode .ant-col {
  height: 100%;
  padding: 0 !important;
}
.activitycode .ant-row {
  margin: 0 !important;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 3px 5px;
  height: 100%;
  margin-bottom: 0;
  border-right: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0;
}
.activitycode .ant-list-bordered {
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  border-right: none;
  border-bottom: none;
}
.dailyupdate .ant-divider-horizontal {
  margin: 0;
}
.listofpersons .ant-col {
  padding: 5px;
}
@media only screen and (min-width: 768px) {
  .activitycode,
  .comments {
    padding-right: 30px;
    border-right: 1px solid #f0f0f0;
  }
  .colorcode,
  .listofpersons {
    padding-left: 10px;
  }
}
.activitycode,
.colorcode,
.comments,
.listofpersons {
  padding-top: 5px;
  padding-bottom: 5px;
}
.production-calendar .ant-form-item,
.work-order-new .ant-form-item {
  margin-bottom: 0;
}
/* .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: inherit;
} */
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding: 0 !important;
}

.langselect .ant-select {
  line-height: 2.4;
}
.ant-layout-header,
.ant-layout-header {
  height: 45px;
  line-height: 45px;
}
.productioncalender-search {
  position: absolute;
  left: calc(50% - 90px);
  z-index: 999;
  top: -35px;
}
.productioncalender-search1 {
  position: absolute;
  right: 250px;
  z-index: 999;
  top: -34px;
}
.ant-table {
  color: rgba(0, 0, 0, 1);
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: solid 1px #c3c3c3;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #c3c3c3;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #c3c3c3;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #d4d4d4;
}
.ant-table-tbody td:hover {
  background: #ffe9e3 !important;
}
.ant-form-item-control-input {
  min-height: inherit;
}
.ant-layout-footer {
  padding: 5px 5px;
  font-size: 12px;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f2efef;
  border-color: #adadad !important;
}
.ant-form label {
  font-size: 12px;
}
.ant-picker-year-panel .ant-picker-content {
  height: auto;
}
/* .ant-select:hover .ant-select-clear {
  opacity: 0;
} */
.ant-input-sm {
  padding: 0px 5px;
}
.newworder-latest .ant-btn-text {
  font-size: 12px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  padding: 0;
}
.ant-menu-inline-collapsed {
  width: 60px;
}
.table-head-new .ant-table-thead > tr > th {
  font-size: 10px;
}
.ant-table-row-expand-icon {
  float: none;
}
.ant-table .ant-table-expanded-row-fixed {
  padding: 0px 16px;
  margin: 5px -17px !important;
}
.collapsed-logo {
  display: none;
}
.normal-logo {
  display: inline-block;
}
.ant-layout-sider-collapsed .collapsed-logo {
  display: inline-block;
}
.ant-layout-sider-collapsed .normal-logo {
  display: none;
}
.table-head-new td {
  background: #fff;
}
.production-calendar .ant-table-tbody .editable-field {
  background: #feece7 !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td {
  border-right: 1px solid #c3c3c3;
}

.dashboard-new .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 46px);
  padding-right: calc(2em + 45px);
}
.work-order-new
  .ant-table-tbody
  .ant-table-container
  .ant-table-thead
  > tr
  > th {
  font-size: 10px;
}
@media (max-width: 767px) {
  .productioncalender-search {
    position: relative;
    top: 0;
    left: 12px;
    width: 100%;
    margin-top: 5px;
  }
  .productioncalender-search1 {
    position: relative;
    top: 0;
    left: 12px;
    right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 1199px) and (min-width: 768px) {
  .productioncalender-search {
    left: 44px;
  }
}
@media (min-width: 1200px) {
  .dashboard-new .ant-table-body {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.hideForThermometre {
  display: none;
}
.thermometerTable {
  padding: 5px;
}
.inner-thermometre {
  width: 100%;
}
.inner-thermometre .width1 {
  text-align: left;
  height: 100%;
}
.inner-thermometre .width1 h5 span {
  color: #ff6e40;
  font-size: 14px;
}
.inner-thermometre .width1 h5 {
  margin-bottom: 0;
  padding: 0 10px;
}
.thermometre-card {
  padding: 5px;
}

.thermometre-card .ant-card-body {
  padding: 0px;
}
.thermometre-card .ant-card-head-title {
  padding: 5px 0 !important;
}
.thermometre-card .ant-card-small > .ant-card-head {
  min-height: 30px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  border-bottom: solid 1px #d9d9d9;
  background: #ffe9e3;
}
.thermometre-card .ant-card-bordered {
  border: solid 1px #d9d9d9;
  background-color: transparent;
}
.thermometre-card .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}
.thermometre-card .ant-collapse {
  border: none;
  border-top: solid 1px #d9d9d9;
  background-color: transparent;
}
.thermometre-card .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  text-align: center;
  padding: 2px 2px;
  color: #1677ff;
  font-size: 12px;
}
.thermometre-card .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.thermometre-card .ant-collapse-content-box p {
  margin-bottom: 0;
}
.thermometre-card .ant-table.ant-table-bordered > .ant-table-container {
  border: none;
  border-left: 1px solid #c3c3c3;
}
.thermometre-main {
  position: relative;
  background: #f9f9f9;
}
.thermometre-main-back {
  position: absolute;
  background-color: rgba(24, 255, 26, 0.2);
  height: 97px;
}
.ant-table-column-sorters {
  padding: inherit;
}
.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead th.ant-table-column-sort {
  background: #555555;
}
.production-calendar-yearfilter {
  left: 75px;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .production-calendar-yearfilter {
    left: inherit;
    right: 0;
    position: relative;
    top: 0;
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .production-calendar-yearfilter {
    left: 11px;
    top: 7px;
    position: relative;
  }
  .prodButtonPadding .ant-btn {
    padding: 4px 9px;
  }
}
.inventoryfilters .ant-form-item {
  margin-bottom: 0rem;
}
.inventory-group-by .ant-table-wrapper {
  margin: 20px 0;
}

.inventory-group-by .ant-table-row-expand-icon-cell {
  width: 50px;
}
.inventory-group-by
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: -16px 15px -16px 15px;
}
